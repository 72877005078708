<template>
    <div class="main">
      <template v-if="classData.length === 0">
        <Empty />
      </template>
      <el-row class="my-class" v-else>
        <el-row class="my-class" style="margin-top: 0px">
          <el-row style="width: 280px; float: right">
            <el-popover placement="start" trigger="click">
              <el-radio-group
                style="float: right"
                v-model="radio"
                @change="radioSort"
                type="vertical"
              >
                <el-radio :label="1">默认排序</el-radio>
                <el-radio :label="2">按标题排序</el-radio>
              </el-radio-group>
              <div slot="reference">
                <el-button
                  style="font-size: 30px; float: right"
                  type="text"
                  icon="el-icon-s-operation"
                />
              </div>
            </el-popover>
          </el-row>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8" v-for="(item, index) in classData" :key="index">
            <el-card class="my-item">
              <h3 v-text="item.nick" class="item-tit"></h3>
              <p class="item-plan">
                <span>学习进度</span>
                <el-row
                  style="display: inline-block; width: 70%; margin-left: 8px"
                >
                  <el-progress
                    class="item-plan"
                    :percentage="
                      item.completePercent == null
                        ? 0
                        : Math.round(item.completePercent)
                    "
                  ></el-progress>
                </el-row>
              </p>
              <el-button
                class="item-btn"
                type="primary"
                @click="joinStudy(item)"
                size="small"
                >进入学习</el-button
              >
            </el-card >
          </el-col>
        </el-row>

        <el-pagination
          style="margin-top: 35px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </el-row>
    </div>
</template>

<script>
import Empty from "@/components/empty";
import { getCourseList } from "@/api/businesscourse/train";
export default {
  name: "train",
  components: {
    Empty,
  },
  data() {
    return {
      classData: [],
      radio: 1,
      originArray: [],
      pagination: {
        currentPage: 1,
        pageSize: 9,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      channel: null,
    };
  },
  created() {
    this.getCourseList();
  },
  methods: {
    radioSort() {
      if (this.radio === 2) {
        this.sortChinese(this.classData, "nick");
      } else if (this.radio === 1) {
        this.classData = [];
        this.originArray.forEach((item) => {
          this.classData.push(item);
        });
      }
    },
    sortChinese(arr, dataLeven) {
      // 参数：arr 排序的数组; dataLeven 数组内的需要比较的元素属性
      /* 获取数组元素内需要比较的值 */
      function getValue(option) {
        // 参数： option 数组元素
        if (!dataLeven) return option;
        var data = option;
        dataLeven.split(".").filter(function (item) {
          data = data[item];
        });
        return data + "";
      }
      arr.sort(function (item1, item2) {
        return getValue(item1).localeCompare(getValue(item2), "zh-CN");
      });
    },

    getCourseList() {
      getCourseList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        isNomal: true,
        type: "培训",
      }).then((res) => {
        if (res.state === "success") {
          this.radio = 1;
          this.originArray = [];
          const ress = {
            body: {
              total: 5,
              pages: 1,
              list: [
                {
                  id: "5a09c674-3dcf-418f-8139-8c09ddceefdb",
                  catalogId: "0bab16c9-db5a-43c3-92f5-2ba842fbde4f",
                  title: "人工智能科普（S）",
                  nick: "图形化编程课（scratch语言）",
                  teacherFees: 5.0,
                  status: "已发布",
                  creatorId: "ccbd81b9-c164-420d-9896-952d66134102",
                  updateTime: "2021-04-15 15:31:50.0",
                  createTime: "2020-10-15 09:45:10.0",
                  type: "常规",
                  catalogInfo: null,
                  openTime: null,
                  openStatus: null,
                  taskCountNum: null,
                  taskCountFinished: null,
                  catalogTitle: "公办校内课",
                  creatorName: "倪巍爽",
                  completePercent: 2.04,
                  taskCount: 49,
                  unitCount: 8,
                },
                {
                  id: "0bb6d333-6ab7-4117-bf13-0237055fcc40",
                  catalogId: "0451b3c2-42ab-48f2-aad1-4772e4f875ef",
                  title: "ALPD——DDD高手进阶课",
                  nick: "ALPD——DDD高手进阶课",
                  teacherFees: null,
                  status: "已发布",
                  creatorId: "1e0287b9-877a-4218-8782-287ef2fcbe6a",
                  updateTime: "2021-09-08 18:41:08.0",
                  createTime: "2021-02-16 09:33:33.0",
                  type: "常规",
                  catalogInfo: null,
                  openTime: null,
                  openStatus: null,
                  taskCountNum: null,
                  taskCountFinished: null,
                  catalogTitle: "研发培训",
                  creatorName: "唐超",
                  completePercent: 0.0,
                  taskCount: 13,
                  unitCount: 1,
                },
                {
                  id: "1025",
                  catalogId: "483811a2-18f7-4a5c-92b1-1fc9d5ec13da",
                  title: "P系列_基础",
                  nick: "P系列_基础",
                  teacherFees: 5.0,
                  status: "已发布",
                  creatorId: "admin",
                  updateTime: "2020-04-03 17:11:34.0",
                  createTime: "2020-01-04 18:29:25.0",
                  type: "常规",
                  catalogInfo: null,
                  openTime: null,
                  openStatus: null,
                  taskCountNum: null,
                  taskCountFinished: null,
                  catalogTitle: "P系列【新版】",
                  creatorName: "管理员",
                  completePercent: 0.74,
                  taskCount: 407,
                  unitCount: 41,
                },
                {
                  id: "3a62aacb-8b12-4730-9005-a8597d40cfd3",
                  catalogId: "19e5388e-d66b-42da-9caa-bbbf8bd9ce41",
                  title: "企业文化",
                  nick: "企业文化",
                  teacherFees: null,
                  status: "已发布",
                  creatorId: "051aa636-0205-4e12-85d1-a3875c76ebd0",
                  updateTime: "2021-09-08 18:41:08.0",
                  createTime: "2020-12-18 22:18:47.0",
                  type: "常规",
                  catalogInfo: null,
                  openTime: null,
                  openStatus: null,
                  taskCountNum: null,
                  taskCountFinished: null,
                  catalogTitle: "培训",
                  creatorName: "许浩然",
                  completePercent: 0.0,
                  taskCount: 1,
                  unitCount: 1,
                },
                {
                  id: "e1569551-f79e-4753-9b2b-8c8baf328350",
                  catalogId: "0451b3c2-42ab-48f2-aad1-4772e4f875ef",
                  title: "以领域为核心的架构和设计方法",
                  nick: "超越DDD——以领域为核心的架构和设计方法",
                  teacherFees: null,
                  status: "已发布",
                  creatorId: "1e0287b9-877a-4218-8782-287ef2fcbe6a",
                  updateTime: "2021-09-08 18:41:08.0",
                  createTime: "2020-07-28 10:10:01.0",
                  type: "常规",
                  catalogInfo: null,
                  openTime: null,
                  openStatus: null,
                  taskCountNum: null,
                  taskCountFinished: null,
                  catalogTitle: "研发培训",
                  creatorName: "唐超",
                  completePercent: 0.0,
                  taskCount: 5,
                  unitCount: 1,
                },
              ],
            },
            state: "success",
            errCode: null,
            errMsg: null,
            positionCode: null,
          };
          res.body.list.forEach((item) => {
            this.originArray.push(item);
          });
          this.classData = res.body.list;
          this.pagination.total = res.body.total;
        } else {
          $msg(res.errMsg, 2);
        }
      });
    },
    // 进入学习
    joinStudy(item) {
      this.$router.push({
        path: "/trains/course-in/" + item.id,
        query: {
          id: item.id,
          subTitle: item.nick,
        },
        // params:{
        //   id: item.id,
        //   t: new Date().getTime(),
        //   subTitle: item.nick,
        // }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getCourseList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getCourseList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/courses.scss";
.index-con{
}
/deep/ .el-pagination {
  font-weight: normal;
  float: none;
  margin-right: 0;
  margin: 36px 0;
  display: flex;
  justify-content: center;
}

/deep/ .el-progress__text {
  margin-left: 12px;
}
</style>
